import { useTranslate } from "../../../../customHooks";
import { isPort, validSourceName } from "../../../../utils/validate";
import { Button, Field, Link, Banner } from "@panwds/react-ui";
import { usePermissions } from "../../../../customHooks";
import { useCallback, useMemo, useState, useEffect } from "react";
import { FormLayout, Input, SubmitButton, Checkbox } from "@panwds/react-form";
import { isEmpty } from "lodash";
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import CustomIncludeExcludeNetworksTable from "./UserIDComponents/CustomIncludeExcludeNetworksTable";
import { CustomIncludeExcludeCreate } from "./UserIDComponents/CustomIncludeExcludeCreate";
import { CustomIncludeExcludeEdit } from "./UserIDComponents/CustomIncludeExcludeEdit";
import { makeStyles } from "@material-ui/styles";
import { toast } from "../../../../components";

type PanelType = "" | "createIncludeExclude" | "editIncludeExclude";

const useStyles = makeStyles((theme) => ({
    header: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '20px',
        color: "#333333",
        padding: '16px 0'
    }
}));

const Index = (props: { firewallData: Record<string, any>; isLoading: boolean; }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { permissions } = usePermissions();
    const region = new URLSearchParams(location.search).get("region");
    const [updateFirewall] = useUpdateFirewallMutation();
    const intl = useIntl();
    const [sidePanel, setSidePanel] = useState<PanelType>("");
    const [editData, setEditData] = useState(null);
    const cftLink = props?.firewallData?.Firewall?.UserID?.CFTURLLink;
    const userIDStatus = props?.firewallData?.Firewall?.UserID?.UserIDStatus;
    const firewallId = props?.firewallData?.Firewall?.FirewallId;
    const updateToken = props?.firewallData?.Firewall?.UpdateToken;
    const deploymentUpdateToken =props?.firewallData?.Firewall?.DeploymentUpdateToken;
    const [showSuccessBanner, setShowSuccessBanner] = useState(true);
    const [showErrorBanner, setShowErrorBanner] = useState(true);
    const isPending = /EndpointPending|DisablePending|UpdatePending/.test(userIDStatus);
    const isError = /EndpointFail|CommitFail|UpdateFail/.test(userIDStatus);
    const isSuccess = userIDStatus === "Enabled";

    const config = { Firewall: ["FirewallId", "UserID", "PrivateAccessConfig", "UpdateToken"] };

    // Memoized function to transform the default form values
    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }
        // Extract fields from firewall describe response based on the config.
        // Extracts fields needed for firewall user id info form.
        const newStateWithConfig: any = extractFieldsWithConfig(props?.firewallData, config);
        const agentName = newStateWithConfig?.Firewall?.UserID?.UserIDStatus === undefined && newStateWithConfig?.Firewall?.UserID?.AgentName === "useridAgent"
        ? `agent-${newStateWithConfig?.Firewall?.FirewallId}`
        : newStateWithConfig?.Firewall?.UserID?.AgentName;

        return {
            Firewall: {
                ...newStateWithConfig?.Firewall,
                AgentName: agentName
            },
        };
    }, [props?.firewallData]);

    /**
     * Transforms the form data for firewall update submission.
     * This function processes the form data, applies necessary transformations,
     * and prepares the data structure required for the firewall user id info update.
     */
    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }

        // Extract the necessary fields
        const { PrivateAccessConfig, UserID } = formData.Firewall;

        // Convert port to integer if necessary
        const portAsInt = parseInt(UserID.Port, 10);

        // Create the transformed data object
        let transformedData = {
            ...formData.Firewall,
            PrivateAccessConfig: {
                ResourceID: PrivateAccessConfig.ResourceID,
                Type: "NetworkLoadBalancer", // Add the Type property here
            },
            UserID: {
                ...UserID,
                Port: isNaN(portAsInt) ? UserID.Port : portAsInt,
                UserIDConfig: PrivateAccessConfig.EnablePrivateAccess || false, // Use EnablePrivateAccess for UserIDConfig
            },
            FirewallId: firewallId,
            Region: region || '',
            UpdateToken: updateToken,
            DeploymentUpdateToken: deploymentUpdateToken
        };

        // Remove PrivateAccessConfig.EnablePrivateAccess
        delete transformedData.PrivateAccessConfig.EnablePrivateAccess;
        return transformedData;
    }, [firewallId, updateToken, deploymentUpdateToken]);

    const onSubmit = useCallback(async (data) => {
        try {
            const result = await updateFirewall({
                payload: transformFormSubmitValue(data),
            }).unwrap();
        }
        catch (error: any) {
            toast.error(`${error?.code}: ${error?.error}`, {toastId: "update-firewall-user-id"});
        }
    }, [history, permissions, transformFormSubmitValue]);

    const renderBanner = () => {
        if (isPending) {
            return (
                <Banner type="inline" appearance="warning" showIcon>
                    {translate('resources.firewallsV2.userid.pendingBannerMsg')}
                </Banner>
            );
        } else if (isError && showErrorBanner) {
            return (
                <Banner type="inline" appearance="error" showIcon>
                    {translate('resources.firewallsV2.userid.errorBannerMsg')}
                </Banner>
            );
        } else if (isSuccess && showSuccessBanner) {
            return (
                <Banner type="inline" appearance="success" showIcon onClose={() => setShowSuccessBanner(false)}>
                    {translate('resources.firewallsV2.userid.successBannerMsg')}
                </Banner>
            );
        }
        return null;
    };

    const formMethods = useForm({ defaultValues: transformFormDefaultValue });

    const { formState: { errors, isDirty }, control, setValue, getValues, register, watch, reset } = formMethods;

    const isUserIDEnabled = watch(
        "Firewall.PrivateAccessConfig.EnablePrivateAccess",
        !!props?.firewallData?.Firewall?.PrivateAccessConfig?.EnablePrivateAccess
    );

    const [customIncludeExcludeNetworks, setCustomIncludeExcludeNetworks] = useState(
        props?.firewallData?.Firewall?.UserID?.CustomIncludeExcludeNetwork || []
    );

    const extractTemplateURL = (url: string) => {
        const key = "templateURL";
        let start = url.indexOf(key);
        if (start === -1) {
            return null;
        }
        start += key.length + 1;
        const end = url.indexOf("&", start);
        const cftUrl = url.substring(start, end === -1 ? url.length : end);
        return decodeURIComponent(cftUrl);
    };

    const builddownloadCFTLinks = (cftUrl: string) => {
        if (!cftUrl) return null;
        const cftTemplateUrl = extractTemplateURL(cftUrl);

        return (
            <a href={cftTemplateUrl || "#"} download style={{ color: "#006FCC", fontSize: "inherit" }}>
                {translate(`resources.firewallsV2.userid.downloadCFT`)}
            </a>
        );
    };

    const builLaunchCFTLinks = (cftUrl: string) => {
        if (!cftUrl) return null;

        return (
            <Link external href={cftUrl} target="_blank" rel="noreferrer" className="tw-text-blue-600 tw-text-inherit">
                {translate(`resources.firewallsV2.userid.launchCFT`)}
            </Link>
        );
    };

    const createIncludeExcludeNetwork = useCallback((data) => {
        const updatedNetworks = [...customIncludeExcludeNetworks, data];
        setCustomIncludeExcludeNetworks(updatedNetworks);
        setValue("Firewall.UserID.CustomIncludeExcludeNetwork", updatedNetworks, { shouldDirty: true });
        setSidePanel("");
    }, [customIncludeExcludeNetworks, setValue]);

    const deleteIncludeExcludeNetwork = useCallback((row) => {
        const currentNetworks = getValues("Firewall.UserID.CustomIncludeExcludeNetwork");
        setValue("Firewall.UserID.CustomIncludeExcludeNetwork", currentNetworks.filter(
            (network: any) => network?.Name !== row?.Name), { shouldDirty: true });
    }, [setValue, getValues]);

    const updateIncludeExcludeNetwork = useCallback((data) => {
        const updatedNetworks = customIncludeExcludeNetworks.map(
            (network: any) => (network.Name === data.Name ? data : network));
        setCustomIncludeExcludeNetworks(updatedNetworks);
        setValue("Firewall.UserID.CustomIncludeExcludeNetwork", updatedNetworks, { shouldDirty: true });
        setSidePanel("");
    }, [customIncludeExcludeNetworks, setValue]);

    const handleRowClick = (rowData: any) => {
        setEditData(rowData);
        setSidePanel("editIncludeExclude");
    };

    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    useEffect(() => {
        if (!isUserIDEnabled) {
            setShowErrorBanner(false);
        }
    }, [isUserIDEnabled]);

    return (
        <div>
            <div className={classes.header}>{translate(`resources.firewallsV2.userId`)}</div>
            {renderBanner()}
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <FormLayout>
                        <Field
                            control={
                                <Checkbox
                                    checked={isUserIDEnabled}
                                    {...register("Firewall.PrivateAccessConfig.EnablePrivateAccess")}
                                    onChange={(e: any) => {
                                        setValue("Firewall.PrivateAccessConfig.EnablePrivateAccess", e.target.checked);
                                        if (!e.target.checked) {
                                            setShowErrorBanner(false);
                                        }
                                    }}
                                    label={translate("resources.firewallsV2.userid.enableUserID")}
                                    disabled={isPending}
                                />
                            }
                            description={intl.formatMessage(
                                {
                                    id: "resources.firewallsV2.userid.enableUserIDHelpText",
                                },
                                {
                                    document: (
                                        <Link
                                            dataMetrics="cloudngfw-aws-network-load-balancer-help"
                                            external
                                            href="https://docs.paloaltonetworks.com/pan-os/10-2/pan-os-admin/user-id"
                                        >
                                            {translate(`generic.document`)}
                                        </Link>
                                    ),
                                }
                            )}
                            dataMetrics="cloudngfw-firewall-edit-enable-aws-network-load-balancer"
                        />
                        {isUserIDEnabled && (
                            <>
                                <Field
                                    control={
                                        <Input
                                            label={translate(`resources.firewallsV2.userid.amazonResourceARN`)}
                                            dataMetrics="cloudngfw-firewall-edit-amazon-resource-arn"
                                            disabled={!isDirty || isPending || isSuccess}
                                            error={errors.Firewall?.PrivateAccessConfig?.ResourceID?.message}
                                            description={
                                                <FormattedMessage
                                                    id="resources.firewallsV2.userid.amazonResourceARNHelpText"
                                                    values={{
                                                        downloadCFT: builddownloadCFTLinks(cftLink),
                                                        launchCFT: builLaunchCFTLinks(cftLink),
                                                    }}
                                                />
                                            }
                                            {...register("Firewall.PrivateAccessConfig.ResourceID", {
                                                required: "This field is required",
                                                minLength: {
                                                    value: 20,
                                                    message: "Minimum length is 20",
                                                },
                                            })}
                                            tooltip={translate(`resources.firewallsV2.userid.amazonResourceARNTooltip`)}
                                        />
                                    }
                                />
                                <Field
                                    control={
                                        <Input
                                            label={translate(`resources.firewallsV2.userid.port`)}
                                            dataMetrics="cloudngfw-firewall-edit-amazon-resource-arn"
                                            description={translate(`resources.firewallsV2.userid.portHelpText`)}
                                            defaultValue={5007}
                                            error={errors.Firewall?.UserID?.Port?.message}
                                            disabled={!isDirty || isPending || isSuccess}
                                            {...register("Firewall.UserID.Port", {
                                                required: "This field is required",
                                                validate: isPort,
                                            })}
                                        />
                                    }
                                />
                                <Field
                                    control={
                                        <Input
                                            label={translate(`resources.firewallsV2.userid.sourceName`)}
                                            dataMetrics="cloudngfw-firewall-edit-source-name"
                                            description={translate(`resources.firewallsV2.userid.sourceNameHelpText`)}
                                            disabled={!isDirty || isPending || isSuccess}
                                            error={errors.Firewall?.UserID?.AgentName?.message}
                                            {...register("Firewall.UserID.AgentName", {
                                                required: "This field is required",
                                                maxLength: {
                                                    value: 31,
                                                    message: "Maximum length is 31",
                                                },
                                                validate: validSourceName,
                                            })}
                                            tooltip={translate(`resources.firewallsV2.userid.sourceNameTooltip`)}
                                        />
                                    }
                                />
                                <Field
                                    control={
                                        <Input
                                            label={translate(`resources.firewallsV2.userid.collectorName`)}
                                            name={"Firewall.UserID.CollectorName"}
                                            dataMetrics="cloudngfw-firewall-edit-collector-name"
                                            description={intl.formatMessage(
                                                {
                                                    id: "resources.firewallsV2.userid.collectorNameHelpText",
                                                },
                                                {
                                                    document: (
                                                        <Link
                                                            dataMetrics="cloudngfw-collector-name-help"
                                                            external
                                                            href="https://docs.paloaltonetworks.com/panorama/10-2/panorama-admin/manage-firewalls/redistribute-user-id-information-to-managed-firewalls"
                                                        >
                                                            {translate(`generic.document`)}
                                                        </Link>
                                                    ),
                                                }
                                            )}
                                            disabled={!isDirty || isPending || isSuccess}
                                            register={{ required: false }}
                                        />
                                    }
                                />
                                <Field
                                    control={
                                        <Input
                                            label={translate(`resources.firewallsV2.userid.awsSecretKeyARN`)}
                                            dataMetrics="cloudngfw-firewall-edit-aws-secret-key-arn"
                                            name="Firewall.UserID.AWSSecretKeyARN"
                                            description={intl.formatMessage(
                                                {
                                                    id: "resources.firewallsV2.userid.awsSecretKeyARNHelpText",
                                                },
                                                {
                                                    document: (
                                                        <Link
                                                            dataMetrics="cloudngfw-collector-name-help"
                                                            external
                                                            href="https://docs.aws.amazon.com/secretsmanager/latest/userguide/whats-in-a-secret.html"
                                                        >
                                                            {translate(`generic.document`)}
                                                        </Link>
                                                    ),
                                                }
                                            )}
                                            disabled={!isDirty || isPending || isSuccess}
                                            register={{ required: false }}
                                        />
                                    }
                                />
                                <Controller
                                    name="Firewall.UserID.CustomIncludeExcludeNetwork"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <CustomIncludeExcludeNetworksTable
                                            deleteNetwork={deleteIncludeExcludeNetwork}
                                            setSidePanel={setSidePanel}
                                            onRowClick={handleRowClick}
                                            {...field}
                                            label="Custom Field"
                                            userIDStatus={formMethods.watch("Firewall.UserID.UserIDStatus")}
                                            disabled={!isDirty || isPending || isSuccess}
                                        />
                                    )}
                                />
                            </>
                        )}
                        <div className="tw-flex tw-justify-end">
                            <Button onClick={handleCancel} disabled={!isDirty} style={{ marginRight: '5px' }}>
                                {translate(`generic.cancel`)}
                            </Button>
                            <SubmitButton disabled={!isDirty}>
                                {translate(`generic.save`)}
                            </SubmitButton>
                        </div>
                    </FormLayout>
                </form>
                {sidePanel === "createIncludeExclude" && (
                    <CustomIncludeExcludeCreate
                        close={() => setSidePanel("")}
                        createNetwork={createIncludeExcludeNetwork}
                        items={customIncludeExcludeNetworks}
                    />
                )}
                {sidePanel === "editIncludeExclude" && (
                    <CustomIncludeExcludeEdit
                        close={() => setSidePanel("")}
                        updateNetwork={updateIncludeExcludeNetwork}
                        items={customIncludeExcludeNetworks}
                        initialData={editData} // Pass the data to be edited
                    />
                )}
            </FormProvider>
        </div>
    );
};

export default Index;