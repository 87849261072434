import { PANTitle, toast } from '../../components';
import {PANWDSBreadcrumbs} from "../../components/PANWDSElements";
import { useParams, withRouter} from "react-router-dom";
import { useTranslate } from '../../customHooks';
import { Tab, TabList, TabPanel, Tabs } from '@panwds/react-ui';
import { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FirewallGeneralInfo, FirewallOverview, FirewallUserID, FirewallEndpoints, FirewallEgressNat } from './Components/Edit';
import { useGetFirewallByIdQuery } from '../../redux/services/firewalls-service';

const useStyles = makeStyles((theme) => ({
    customActiveTabs: {
        '& div[data-testid*="tab-tab"][class*="active:tw-border-l"]': {
            borderLeftWidth: '4px',
            borderLeftColor: '#38A5FF',
        }
    },
  }));

const FirewallEdit = (props: any) => {
    const translate = useTranslate();
    const { firewallname } = useParams<{ firewallname: string }>();
    const [activeTabId, setActiveTabId] = useState<string>(sessionStorage.getItem('fwEditActiveTab') || 'overview')
    
    const handleTabChange = useCallback((tabId: string) => { 
        sessionStorage.setItem('fwEditActiveTab', tabId);
        setActiveTabId(tabId);
    }, [])

    const breadcrumbMapping = {
        ngfirewalls: `${translate('resources.firewallsV2.shortTitle')}`,
        [firewallname]: firewallname,
    };
    const classes = useStyles();
    const region = new URLSearchParams(location.search).get('region') || '';

    const { data: firewall, error, isLoading: fwDetailsLoading } = useGetFirewallByIdQuery({ fwid: firewallname, region });

    return (
        <>
            <PANWDSBreadcrumbs
                mapping={breadcrumbMapping}
            />
            <PANTitle
                title={firewallname}
            />
            <PANTitle divider={false} />
            <div className='tw-p-4 tw-h-full'>
                <Tabs orientation="vertical" addClassName={classes.customActiveTabs}>
                    <TabList activeTabId={activeTabId} onActiveChange={nextTabId => handleTabChange(nextTabId)}>
                        <Tab id="overview">
                            {translate('resources.firewallsV2.overview')}
                        </Tab>
                        <Tab id="rules">
                            {translate('resources.firewallsV2.rules')}
                        </Tab>
                        <Tab id="general">
                            {translate('resources.firewallsV2.generalInfo')}
                        </Tab>
                        <Tab id="policies">
                            {translate('resources.firewallsV2.securityPolicies')}
                        </Tab>
                        <Tab id="egress">
                            {translate('resources.firewallsV2.egressNat')}
                        </Tab>
                        <Tab id="userId">
                            {translate('resources.firewallsV2.userId')}
                        </Tab>
                        <Tab id="endpoints">
                            {translate('resources.firewallsV2.endpointManagement')}
                        </Tab>
                        <Tab id="logProfile">
                            {translate('resources.firewallsV2.logAndMetrics')}
                        </Tab>
                        <Tab id="decryption">
                            {translate('resources.firewallsV2.decryption')}
                        </Tab>
                    </TabList>
                    <div className='tw-m-0 tw-flex-1 tw-bg-white tw-border-0 tw-border-l-2 tw-border-gray-300 tw-border-solid'>
                        <TabPanel activeTabId={activeTabId} forTabId="overview">
                            <FirewallOverview fwid={firewallname} region={region} />
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="rules">
                            Tab 2 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="general">
                            <FirewallGeneralInfo key={firewall} firewallData={firewall} isLoading={fwDetailsLoading} />
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="policies">
                            Tab 4 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="egress">
                            <FirewallEgressNat firewallData={firewall} isLoading={fwDetailsLoading} />
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="userId">
                            <FirewallUserID firewallData={firewall} isLoading={fwDetailsLoading} />
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="endpoints">
                            <FirewallEndpoints key={firewall} firewallData={firewall} isLoading={fwDetailsLoading} />
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="logProfile">
                            Tab 8 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="decryption">
                            Tab 9 Content
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </>
    );
};
export default withRouter(FirewallEdit);